.DesktopNavbar{
  height: calc(20vh - 40px);
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to bottom, rgba(255,255,255,.7), rgba(255,255,255,0));
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,.3), rgba(0,0,0,0)); */
  align-items: center;
  direction: ltr;
  margin-bottom: 40px;
}

.Navbar {
  overflow: hidden;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 25vh;
  max-height: 25vh;

}

.nav-links {
  display: flex;
  list-style: none;
}

.img {
  display: flex;
  width: 250px;
  vertical-align: middle;
  padding-right: 30px;
}

.desktop-link:first-child:hover::before {
  opacity: 0;
}

.gallery .gallery-btn {
  cursor: pointer;
  color: black;
  font-size: 16px;
  border: none;
  padding: 0px 30px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  letter-spacing: 5px;
  /* font-family: 'Gayathri', sans-serif; */
  padding-bottom: 15px;
  margin-top: 15px;
}

.line {
  color: black;
  background-color: black;
  width: 2px;
  height: 25px;
  margin-left: 10px;
  border-radius: 8px;
}

.desktop-link {
  font-size: 16px;
  display: inline-block;
  padding: 0px 30px;
  text-decoration: none;
  /* font-family: 'Gayathri', sans-serif; */
  color: black;
  letter-spacing: 5px;
  cursor: pointer;
}

.gallery {
  overflow: hidden;
  color: black;
}

.nav-links .desktop-link:hover,
.gallery:hover .gallery-btn {
  /* color: #b75254; */

  opacity: 1;

}

.desktop-link:hover::before, .gallery-btn:hover::before {
  opacity: 1;
}

.gallery-menu {
  transition: all .3s;
  opacity: 0;
  position: absolute;
  color: #b75254;
  min-width: 160px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .2);
  z-index: 999;
  background-color: rgba(255,255,255,1);
}

.desktop-link::before, .gallery-btn::before{
  content: " ";
  display: block;
  position: absolute;
  left: 50%;
  bottom: -25px;
  top: calc(100% + 0.5rem);
  transform: translateX(-50%);
  border-top: 1px solid;
  width: calc(100% + 0.6875rem);
  opacity: 0;
  transition: opacity .5s;
  position: relative;
  transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
}

.gallery-menu .desktop-link{
  float: none;
  color: black;
  padding: 10px 30px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.gallery:hover .gallery-menu {
  opacity: 1;
}

.App-header-desktop {
  display: inline-block;
}

.change-language-desktop {
  background: none;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 25px;
  font-size: 15px;
}
