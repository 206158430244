
.wrapper {
  padding: 0 1em;
  box-sizing: border-box;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 90px;
}

.top-section img{
  width: 100%;
}

.top-section {
  position: relative;
}

.top-section-text {
  /* display: none; */
}

.bottom-section-text {
  width: 100%;
}

/* Mobile */
@media screen and (max-width: 900px){

.bottom-section-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
}

.bottom-section-images div {
  height: auto;
  position: static;
  margin: 0 !important;
  padding-bottom: 100%;
  /* background-image: url(https://drive.google.com/uc?id=1AguqX_zuhyc19LONsj3Hg3E_JS6pxstB); */
  background-size: cover;
  background-position: center;
  width: auto;
}

.bottom-section-first-image {
  background-image: url(https://drive.google.com/uc?id=1AguqX_zuhyc19LONsj3Hg3E_JS6pxstB);
  background-size: cover;
  background-position: center;
}

.bottom-section-second-image {
  background-image: url(https://drive.google.com/uc?id=12glyagfB7_EiuA52TSrZduo2dAxQBTYa);
  background-size: cover;
  background-position: center;
}

.bottom-section-third-image {
  background-image: url(https://drive.google.com/uc?id=1DEiYPcslksVA_zV-UcJ1XfGki5C8v-Js);
  background-size: cover;
  background-position: center;
}

.bottom-section-forth-image {
  background-image: url(https://drive.google.com/uc?id=1bluQTiBC5z9L25YkeFzcSQ9p4MAx1p04);
  background-size: cover;
  background-position: center;
}


.bottom-section {
  background: #f9f1eb;
  position: relative;
  padding: 10px 10px 0px 10px;
  margin-top: 20px;

}


}

/* Desktop */
@media screen and (min-width: 900px){
  .top-section-text {
    /* display: none; */
    position: absolute;
    background: #f9f1eb;
    top: 10%;
    width: 40%;
    right: 55%;
    padding: 1em 2em;
    /* transform: translateY(-50%); */
    box-sizing: border-box;
    opacity: 0.9;
    font-size: 21px;
    text-align: center;
    /* box-shadow: 2px 3px 5px white; */
  }

  .bottom-section-text-paragraph {
    position: absolute;
    top: 90px;
    left: 80px;
    max-width: 25%;
    word-spacing: 3px;
    letter-spacing: 1px;
  }

.bottom-section {
  background: #faf7f6;
  padding: 150px;
  position: relative;

}

.bottom-section-images div:nth-child(1){
  top: -40px;
  right: 360px;
}

.bottom-section-images div:nth-child(2){
  top: 0;
  right: 50px;
}

.bottom-section-images div:nth-child(3){
  top: 270px;
  right: 360px;
}

.bottom-section-images div:nth-child(4){
  top: 310px;
  right: 50px;
}


.bottom-section-images div{
    display: inline-block;
    width: 16.875rem;
    height: 16.875rem;
    margin-bottom: 1em;
    background-size: cover;
    background-position: center;
    /* background-image: url(https://drive.google.com/uc?id=1AguqX_zuhyc19LONsj3Hg3E_JS6pxstB); */
    position: absolute;
}

.bottom-section-first-image {
  background-image: url(https://drive.google.com/uc?id=1AguqX_zuhyc19LONsj3Hg3E_JS6pxstB);
  background-size: cover;
  background-position: center;
}

.bottom-section-second-image {
  background-image: url(https://drive.google.com/uc?id=12glyagfB7_EiuA52TSrZduo2dAxQBTYa);
  background-size: cover;
  background-position: center;
}

.bottom-section-third-image {
  background-image: url(https://drive.google.com/uc?id=1DEiYPcslksVA_zV-UcJ1XfGki5C8v-Js);
  background-size: cover;
  background-position: center;
}

.bottom-section-forth-image {
  background-image: url(https://drive.google.com/uc?id=1bluQTiBC5z9L25YkeFzcSQ9p4MAx1p04);
  background-size: cover;
  background-position: center;
}

.bottom-section-images {
  text-align: center;
}
.bottom-section-images div:nth-child(2n+1){
  margin-right: 5px;
}
.bottom-section-text {
  min-height: 250px;
}

.mid-section {
  display: flex;
  align-items: center;
  min-height: 300px;
  max-width: 75%;
  margin: auto;
  word-spacing: 3px;
  letter-spacing: 1px;
  font-size: 19px;
}
}
