
.image-gallery-image {
  width: 100%;
  /* height: 0; */
  /* padding-top: 70vh; */
  position: relative;
}

.image-gallery {
  max-width: 90%;
  margin: auto;
}
.image-gallery-image img {
  /* position: absolute; */
  top: 0;
  object-fit: contain;
  object-position: center center;
  width: 100%;
  max-height: 100vh;
}

.image-gallery-right-nav::before {
  color: #a6a5a5;
}

.image-gallery-left-nav::before {
  color: #a6a5a5;
}

.image-gallery-fullscreen-button::before{
  color: #a6a5a5;
}

.image-gallery-play-button::before {
  color: #a6a5a5;
}

.image-gallery-thumbnails {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px){
  .image-gallery-fullscreen-button, .image-gallery-play-button, .image-gallery-right-nav, .image-gallery-left-nav{
    display: none;
  }

}
