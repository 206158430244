
.About {
  padding: 30px;
}

.block {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f9f1eb;
  opacity: 0.9;
  width: 100%;
}

.photographer-block {
  margin-bottom: 30px;
}

.studio-block img {
  order: 2;
}

.block-text {
  margin-bottom: 20px;
}

.block img {
  width: 100%;
  object-fit: contain;
  align-self: flex-start;
}

hr {
  display: none;
  width: 100%;
  margin-left: 0;
}

@media screen and (min-width: 700px) {
  .block {
    flex-direction: row;
  }

  .block img {
    width: 300px;
    align-self: center;
  }

  .photographer-block .block-text {
    margin: 0px 40px;
  }

  .studio-block .block-text {
    margin: 0px 40px;
  }

  .studio-block img {
    order: unset;
  }
}

@media screen and (min-width: 900px) {
  .block {
    /* width: 80%; */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .photographer-block {
    margin-bottom: 60px;
  }

  .photographer-block img{
    margin-left: auto;
    width: 600px;
    height: 350px;
  }

  .studio-block img{
    margin-right: auto;
    width: 600px;
    height: 350px;
  }

  .block-text {
    max-width: 50%;
    word-spacing: 3px;
    letter-spacing: 1px;
  }
}
