.overlay {
    position: fixed;
    left: 50%;
    top: 0;
    width: 100vw;
    z-index: 99999;
    height: 100vh;
    transform: translateX(-50%);
    background-color: rgba(245,245,245,0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;

}

.popup-container {
    position: relative;
    padding: 100px 100px 80px 100px;
    background-color: white;
    border-radius: 8px;
    margin-top: 150px;
    box-shadow: 2px 3px 5px #999;
}

.popup-title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 45px;
    font-weight: 400;
}

.popup-description {
    font-size: 25px;
}

.popup-button {
    position: absolute;
    padding: 10px 25px;
    border: 1px solid #999;
    border-radius: 8px;
    bottom: -30%;
    left: 50%;
    color: white;
    font-size: 18px;
    cursor: pointer;
    background-color: #373a47;
    transform: translateX(-50%);
}
