.input {
  min-width: 100%;
  border: none;
  border-bottom: 1px solid lightgray;
  text-align: center;
  outline: 0;
  font-size: 15px;
  resize: none;
  background-color: white;
  padding: 0px 10px 10px 10px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upper-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
}

.input-container {
  width: 45%;
  display: flex;
  justify-content: center;
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container label {
  margin-bottom: 5px;
}

 .textarea {
   width: 100%;
   margin-top: 20px;
   min-height: 40px;
 }

 .textarea-container {
   width: 45%;
   margin: auto;
   display: flex;
   margin-top: 30px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }

 .submit {
   margin-top: 50px;
   margin-bottom: 30px;
   font-size: 15px;
   background-color: white;
   color: darkgray;
   padding: 5px 50px;
   box-shadow: none;
   background: transparent;
    -webkit-appearance: none;
  color: #786e6e;
 }

 .form-h2, .form-h3 {
   text-align: center;
   color: #786e6e;
   max-width: 90%;
   margin: auto;

 }
.form-h3 {
  margin-top: 20px;
  margin-bottom: 40px;
}

select {
  color: #786e6e;
}
/* 
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

 @media only screen and (max-width: 600px) {
  .input-container {
    width: 80%;
    margin: auto;
    text-align: center;
    text-align-last: center;

  }

  .textarea-container {
    width: 80%;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .submit {
    padding: 10px 50px;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .input {
    margin-bottom: 25px;
  }
}
