.Footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 30px;
  align-items: center;
  font-size: 12px;
  color: #ffff;
  background-color: #373947;
  opacity: 1;
  z-index: 999999;
}

.Footer a {
  font-size: 20px;
  color: #ffff;
  opacity: 0.8;
  padding: 0;
}

.social {
  width: 10%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .social {
    width: 50%;
    margin: auto;
    padding-bottom: 10px;
  }
}
